export const environment = {
  production: false,
  copomex: {
    token: 'xXrd653091zcKPR4aQyGd8ctFumv7QWvMR9M324AWjDEd3Y6Km',
    urlApi: 'https://34noe7h394.execute-api.us-east-1.amazonaws.com/prod/api/v1/zipcodes'
  },
  firebase: {
    projectId: "mechanic-app-los-angeles",
    appId: "1:450475940061:web:81dcfa303ee4d58a67e334",
    storageBucket: "mechanic-app-los-angeles.appspot.com",
    apiKey: "AIzaSyAOXriJ4HuKIvYwfYUH3IFqk1C5UfVtIv4",
    authDomain: "mechanic-app-los-angeles.firebaseapp.com",
    messagingSenderId: "450475940061",
    measurementId: "G-66484MM6W2"
  },
  idCompany: '100685877109325827',
  urlAPI: 'https://6qker9q51e.execute-api.us-east-1.amazonaws.com/dev/api/v1'
};
