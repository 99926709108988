import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth, User, signOut, user } from '@angular/fire/auth';
import { SpinnerService } from './spinner.service';
import { Router } from '@angular/router';
import { AppUser, IAppUser } from '../models/app-user.model';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public loggedIn: boolean = false;

  public currentFirebaseUser?: User;

  public currentUser?: AppUser;

  constructor(
    public auth: Auth,
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private router: Router,
    public toastService: ToastService
  ) {
    user(this.auth).subscribe((_user) => {
      if (_user) {
        console.log('Existe usuario');
        
        this.loggedIn = true;
        this.currentFirebaseUser = _user;
        this.getUser();
      } else {
        console.log('NO Existe usuario');
        this.loggedIn = false;
      }
    });
  }

  /*
  ██████╗ ███████╗████████╗███████╗██╗██████╗ ███████╗██████╗  █████╗ ███████╗███████╗██╗   ██╗███████╗███████╗██████╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔════╝██║██╔══██╗██╔════╝██╔══██╗██╔══██╗██╔════╝██╔════╝██║   ██║██╔════╝██╔════╝██╔══██╗
  ██║  ███╗█████╗     ██║   █████╗  ██║██████╔╝█████╗  ██████╔╝███████║███████╗█████╗  ██║   ██║███████╗█████╗  ██████╔╝
  ██║   ██║██╔══╝     ██║   ██╔══╝  ██║██╔══██╗██╔══╝  ██╔══██╗██╔══██║╚════██║██╔══╝  ██║   ██║╚════██║██╔══╝  ██╔══██╗
  ╚██████╔╝███████╗   ██║   ██║     ██║██║  ██║███████╗██████╔╝██║  ██║███████║███████╗╚██████╔╝███████║███████╗██║  ██║
  ╚═════╝ ╚══════╝   ╚═╝   ╚═╝     ╚═╝╚═╝  ╚═╝╚══════╝╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝

  */
  /**
  * FUNCTION getFirebaseUser
  * 
  * @returns 
  */
  async getFirebaseUser(): Promise<User | null> {
    return new Promise((resolve, reject) => {
      
      if(this.currentFirebaseUser) {
        resolve(this.currentFirebaseUser);
      } else {
        user(this.auth).subscribe((_user) => {
          if (_user) {
            console.log('Existe usuario');
            
            this.loggedIn = true;
            this.currentFirebaseUser = _user;
            this.getUser();
            resolve(_user);
          } else {
            console.log('NO Existe usuario');
            this.loggedIn = false;
            resolve(null);
          }
        });
      }

    });
    
  }

  /*
  ██████╗ ███████╗████████╗████████╗ ██████╗ ██╗  ██╗███████╗███╗   ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔═══██╗██║ ██╔╝██╔════╝████╗  ██║
  ██║  ███╗█████╗     ██║      ██║   ██║   ██║█████╔╝ █████╗  ██╔██╗ ██║
  ██║   ██║██╔══╝     ██║      ██║   ██║   ██║██╔═██╗ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝███████╗   ██║      ██║   ╚██████╔╝██║  ██╗███████╗██║ ╚████║
  ╚═════╝ ╚══════╝   ╚═╝      ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝╚═╝  ╚═══╝

  */
  /**
  * FUNCTION getToken
  * 
  * @returns 
  */
  async getToken(): Promise<String> {
    const user = await this.getFirebaseUser();

    const token = await user?.getIdToken();
    
    return token ?? '';
  }

  /*
  ██████╗ ███████╗████████╗██╗   ██╗███████╗███████╗██████╗
  ██╔════╝ ██╔════╝╚══██╔══╝██║   ██║██╔════╝██╔════╝██╔══██╗
  ██║  ███╗█████╗     ██║   ██║   ██║███████╗█████╗  ██████╔╝
  ██║   ██║██╔══╝     ██║   ██║   ██║╚════██║██╔══╝  ██╔══██╗
  ╚██████╔╝███████╗   ██║   ╚██████╔╝███████║███████╗██║  ██║
  ╚═════╝ ╚══════╝   ╚═╝    ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝

  */
  /**
  * FUNCTION getUser
  */
  async getUser() {
    this.spinnerService.loading = true;
    this.spinnerService.message = 'Cargando datos de usuario';

    const token = await this.currentFirebaseUser?.getIdToken();
    const urlApi = `${environment.urlAPI}/account/`;

    try {

      const response = await new Promise<ServerResponse<{ user: IAppUser }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ user: IAppUser }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token ?? ''
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse<{ user: IAppUser }>
              ) => resolve(
                new ServerResponse<{ user: IAppUser }>(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        this.currentUser = new AppUser(
          response.data?.user!
        );
      }

      console.log(
        this.currentUser,
      );
      

    } catch(error) {
      console.log(error);
    }


    this.spinnerService.loading = false;
    this.spinnerService.message = '';
  }

  /*
  ███████╗██╗ ██████╗ ███╗   ██╗ ██████╗ ██╗   ██╗████████╗
  ██╔════╝██║██╔════╝ ████╗  ██║██╔═══██╗██║   ██║╚══██╔══╝
  ███████╗██║██║  ███╗██╔██╗ ██║██║   ██║██║   ██║   ██║
  ╚════██║██║██║   ██║██║╚██╗██║██║   ██║██║   ██║   ██║
  ███████║██║╚██████╔╝██║ ╚████║╚██████╔╝╚██████╔╝   ██║
  ╚══════╝╚═╝ ╚═════╝ ╚═╝  ╚═══╝ ╚═════╝  ╚═════╝    ╚═╝

  */
  /**
  * FUNCTION signOut
  */
  async signOut() {
    this.spinnerService.loading = true;
    this.spinnerService.message = 'Cerrando sesión';

    await signOut(this.auth);

    this.currentUser = undefined;

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    sessionStorage.removeItem('user');
    this.loggedIn = false;
    this.router.navigate(['login']);
  }

  
  /*
  ███████╗██╗ ██████╗ ███╗   ██╗██╗   ██╗██████╗
  ██╔════╝██║██╔════╝ ████╗  ██║██║   ██║██╔══██╗
  ███████╗██║██║  ███╗██╔██╗ ██║██║   ██║██████╔╝
  ╚════██║██║██║   ██║██║╚██╗██║██║   ██║██╔═══╝
  ███████║██║╚██████╔╝██║ ╚████║╚██████╔╝██║
  ╚══════╝╚═╝ ╚═════╝ ╚═╝  ╚═══╝ ╚═════╝ ╚═╝

  */
  /**
   * FUNCTION signUp
   * 
   * @param user 
   * @returns 
   */
  async signUp(
    user: any
  ): Promise<boolean> {
    let flag = false;

    const urlApi = `${environment.urlAPI}/auth/signup`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Registrando usuario';

    try {


      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi,
            JSON.stringify(user),
            {
              headers: {
                "content-type": "application/json",
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`
                  }
                );
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 201) {
        flag = true;
        this.toastService.show(
          {
            body: response.message,
          }
        );
      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`
        }
      );
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;
  }
}
