// AppUser.model.ts

export interface IAppUser {
  profile: string;
  name: string;
  paternalSurname: string;
  maternalSurname: string;
  uid: string;
  email: string;
  lastLogInAt: Date | string;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export class AppUser {
  profile: string;
  name: string;
  paternalSurname: string;
  maternalSurname: string;
  uid: string;
  email: string;
  lastLogInAt: Date;
  createdAt: Date;
  updatedAt: Date;

  constructor(data: IAppUser) {
    this.profile = data.profile;
    this.name = data.name;
    this.paternalSurname = data.paternalSurname;
    this.maternalSurname = data.maternalSurname;
    this.uid = data.uid;
    this.email = data.email;
    this.lastLogInAt = new Date(data.lastLogInAt);
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
  }

  getFullName(): string {
    return `${this.name} ${this.paternalSurname}  ${this.maternalSurname}`;
  }
}
